import { Carousel } from 'react-bootstrap';
import useWindowSize from '../utils/ScreenSize';


const CustomCarrousel = ({items}) => {

    return (
        <Carousel className='mx-2' width="100%">
            {
                items.map((value, index) => (
                    <Carousel.Item key={index} aspectRatio="4/3">
                        <div style={{width: '100%', margin: '0 auto', alignItems: 'center', alignContent: 'center', justifyContent: 'center', textAlign: 'center'}}>
                            <img style={{width:"100%", aspectRatio: '4/3', objectFit: 'contain', borderRadius: '5px'}} src={`data:image/webp;base64,${value}`}></img>
                        </div>
                    </Carousel.Item>
                ))
            }
        </Carousel>
    )
}

export default CustomCarrousel