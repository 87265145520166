import React, { useState, useEffect } from 'react';

function useWindowSize() {
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    useEffect(() => {
        // Función que actualiza el ancho y alto
        const handleResize = () => {
            setWidth(window.innerWidth);
            setHeight(window.innerHeight);
        };

        // Añadir el event listener para el redimensionamiento
        window.addEventListener('resize', handleResize);

        // Eliminar el event listener cuando se desmonte el componente
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return { width, height };
}

export default useWindowSize;
