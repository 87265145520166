import { React, useState, useEffect} from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import {Navbar, Container, Offcanvas, Nav, NavDropdown, Form, Button} from 'react-bootstrap';


function HomeNavbar() {
    const navigate = useNavigate();

    useEffect(() => {
        getScreenSize()
    }, [])

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [expand, setExpand] = useState('sm')

    const getScreenSize = () => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
    
        // Agregar el evento de redimensionamiento al cargar el componente
        window.addEventListener('resize', handleResize);
    
        // Limpiar el evento de redimensionamiento al desmontar el componente
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }
    

    return (
        <div>
            <Navbar key={expand} expand={expand} className="bg-body-tertiary">
                <Container fluid>
                    <Navbar.Brand href="/">
                        <img height={'50px'} src="/assets/logo-light-wname.webp"></img>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
                    <Navbar.Offcanvas
                        id={`offcanvasNavbar-expand-${expand}`}
                        aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                        placement="end"
                    >
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                LVSprinters
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Nav className="justify-content-center flex-grow-1 pe-3">
                                <Nav.Link href="/">Home</Nav.Link>
                                <Nav.Link href="/vehicles">Vehicles</Nav.Link>
                                <Nav.Link href="/contact">Contact</Nav.Link>
                                <Nav.Link href="/frequent-asked-questions">FAQ</Nav.Link>
                                {/* <NavDropdown
                                    title="Dropdown"
                                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                                >
                                    <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
                                    <NavDropdown.Item href="#action4">
                                    Another action
                                    </NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item href="#action5">
                                    Something else here
                                    </NavDropdown.Item>
                                </NavDropdown> */}
                            </Nav>
                            <Button style={{backgroundColor: '#1567a5'}} variant="primary" href="/sign-in">Sign In</Button>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>
        </div>
    );
}

export default HomeNavbar;