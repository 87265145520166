// import { useState } from "react"
// import CustomGrid from "./CustomGrid"
// import ImageGallery from "react-image-gallery";
// import "react-image-gallery/styles/css/image-gallery.css";


// const CustomImgCarrousel = ({images, defaultIndex}) => {
//     const [selIndex, setSelIndex] = useState(defaultIndex)
//     const height = '300px';

//     // contain o cover

//     return (
//         <CustomGrid xsmall_cols={1} small_cols={1} medium_cols={1} large_cols={1} comps={
//             [
//                 <div style={{width: '100%', height: height, alignContent:'center', alignItems:'center', justifyContent:'center', justifyItems:'center'}}>
//                     <img style={{borderRadius: '20px', objectFit: 'contain', width: '100%', height: height}} src={`data:image/png;base64,${images[selIndex]}`}></img>
//                 </div>,
//                 <div>
//                     <CustomGrid xsmall_cols={2} small_cols={2} medium_cols={2} comps={
//                         images.map((value, index) => (
//                             <div style={{width: '100%', height: '100%', border: selIndex == index? '6px solid #18a8c7' : '', borderRadius: '28px', cursor: 'pointer', alignContent:'center', alignItems:'center', justifyContent:'center', justifyItems:'center'}}>
//                                 <img style={{borderRadius: '20px'}} width={'100%'} src={`data:image/png;base64,${value}`} onClick={ () => { setSelIndex(index) } }></img>
//                             </div>
//                         ))
//                     }></CustomGrid>
//                 </div>
//             ]
//         }></CustomGrid>

//         // <ImageGallery items={images.map((item, _) => ({
//         //     original: `data:image/png;base64,${item}`,
//         //     thumbnail: `data:image/png;base64,${item}`,
//         //     originalHeight: '350vh'
//         // }))}></ImageGallery>
//     )
// }

// export default CustomImgCarrousel