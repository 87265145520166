import { React, useState, useEffect} from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import {Navbar, Container, Offcanvas, Nav, NavDropdown, Form, Button, Row, Col} from 'react-bootstrap';


const CustomGrid = ({xsmall_cols, small_cols, medium_cols, large_cols, comps}) => {
    return (
        <Row xs={xsmall_cols} sm={small_cols} md={medium_cols} lg={large_cols} className="g-4">
            {
                comps.map((value, index) => (
                    <Col key={index}>
                        {
                            value
                        }
                    </Col>
                ))
            }
        </Row>
    )
}

export default CustomGrid