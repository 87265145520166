import { React, useState, useEffect} from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { CustomGET } from "../utils/RestActions";
import HomeNavbar from "../Components/CustomNavbar";
import CustomGrid from "../Components/CustomGrid";
import CustomCarrousel from "../Components/CustomCarrousel";
import CustomImgCarrousel from "../Components/CustomImgCarrousel";
import { Badge, Button } from "react-bootstrap";
import useWindowSize from "../utils/ScreenSize";


const VehicleDetailsPage = () => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        getVanDetails()
    }, [])

    const [vanDetails, setVanDetails] = useState(null)

    const getVanDetails = async () => {
        const queryParams = new URLSearchParams(location.search);
        const id = queryParams.get('id');

        if (sessionStorage.getItem(`van${id}`)) {
            setVanDetails(JSON.parse(sessionStorage.getItem(`van${id}`)))
        }

        try {
            const res = await CustomGET(`/vehicles/details?id=${id}`)
            setVanDetails(res.data.data)
            sessionStorage.setItem(`van${id}`, JSON.stringify(res.data.data))
        } catch {
            alert("Sorry, we could not find the given item, check the url and try again.")
        }
    }


    return (
        <div>
            <HomeNavbar></HomeNavbar>
            <Link style={{color: 'black'}} className="mx-3" to={'/vehicles'}>{'< Explore other vans'}</Link>
            <div className="mt-2"></div>
            {
                vanDetails == null? <div>Loading...</div> : <CustomGrid xsmall_cols={1} small_cols={1} medium_cols={2} comps={[
                    <div>
                        {
                            Array.isArray(vanDetails.images)? <CustomCarrousel items={vanDetails.images}></CustomCarrousel> : <div>Loading...</div>
                        }
                    </div>,
                    <div className="mx-3">
                        <h2>{`${vanDetails.name}`}</h2>
                        <h3>{`${vanDetails.make} ${vanDetails.brand}`}</h3>
                        <h4>
                            {
                                Array.isArray(vanDetails.badges)? vanDetails.badges.map((value, index) => (
                                    <Badge bg="secondary" className="m-1">{value}</Badge>
                                )) : <div>Loading...</div>
                            }
                        </h4>
                        <div className="m-3">
                            {
                                Array.isArray(vanDetails.features)? vanDetails.features.map((item, index) => (
                                    <section key={index}>
                                        <h4>{item.title}</h4>
                                        <ul>
                                            {
                                                Array.isArray(item.items)? item.items.map((item1, index1) => (
                                                    <li>{item1}</li>
                                                )) : <div>Loading...</div>
                                            }
                                        </ul>
                                    </section>
                                )) : <div>Loading...</div>
                            }
                        </div>
                        <Button style={{width: '40%'}} variant="success" className="m-3" onClick={ () => { navigate(`/contact`); } }>Rent now</Button>
                    </div>
                ]}></CustomGrid>
            }
        </div>
    )
}

export default VehicleDetailsPage;