import { React, useState, useEffect} from "react";
import '../App.css';
import HomeNavbar from "../Components/CustomNavbar";
import CustomGrid from "../Components/CustomGrid";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";


const ContactPage = () => {
    const navigate = useNavigate()

    return (
        <div>
            <HomeNavbar></HomeNavbar>
            <div style={{height: '80vh', textAlign: 'center', justifyContent: 'center', alignItems: 'center', alignContent: 'center'}}>
                <CustomGrid xsmall_cols={1} small_cols={1} medium_cols={2} comps={[
                    <div style={{height: '80vh', textAlign: 'center', height: '100%', alignItems: 'center', alignContent: 'center'}}>
                        <h1>Get in Touch with Us!</h1>
                        <p className="mx-5">Feel free to call us at the following telephone number. We're dedicated to making your experience with <strong>LVSprinters</strong> exceptional.</p>
                        <div style={{display: 'flex', textAlign: 'center', justifyContent: 'center', alignContent: 'center', alignItems: 'center'}}>
                            <img src="/assets/phone.webp"></img>
                            <p style={{marginTop: '8px', marginLeft: '15px'}}>+1 (702)-704-3557</p>
                        </div>
                    </div>,
                    <div style={{height: '80vh', textAlign: 'center', height: '100%', alignItems: 'center', alignContent: 'center'}}>
                        <img width={'80%'} src="/assets/contactus.webp"></img>
                    </div>
                ]}></CustomGrid>
            </div>
        </div>
    )
}

export default ContactPage;