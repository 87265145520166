import { React, useEffect, useState} from "react";
import { useNavigate } from 'react-router-dom';
import HomeNavbar from "../Components/CustomNavbar";
import CustomCard from "../Components/CustomCard";
import CustomGrid from "../Components/CustomGrid";
import { CustomGET } from "../utils/RestActions";
import { Button, Badge } from "react-bootstrap";


const VehiclesListPage = () => {
    const navigate = useNavigate();
    const [vanIds, setVanIds] = useState([])
    const [vanList, setVanList] = useState([])
    
    useEffect(() => {
        const fetchVansIds = async () => {
            try {
                const res = await CustomGET('/vehicles/list_ids?page=0&page_size=3')
                setVanIds(res.data.data)
            }
            catch {

            }
        }

        fetchVansIds()
    }, [])

    useEffect(() => {
        const fetchVanPreviews = async () => {
            try {
                let vansData = []

                if (sessionStorage.getItem('vansList')) {
                    vansData = JSON.parse(sessionStorage.getItem('vansList'))
                }

                setVanList([...vansData])
                
                let idx = 0
                for (const id of vanIds) {
                    const resp = await CustomGET(`/vehicles/details_preview?id=${id}`)
                    if (vansData.length == idx) {
                        vansData.push(resp.data.data)
                    }
                    else {
                        vansData[idx] = resp.data.data
                    }
                    setVanList([...vansData])
                    idx += 1
                }
                sessionStorage.setItem('vansList', JSON.stringify(vansData))
            }
            catch {

            }
        }

        if (vanIds.length > 0) {
            fetchVanPreviews()
        }

    }, [vanIds])

    const getVans = async () => {
        const res = await CustomGET('/vehicles/list')
        setVanList(res.data.data)
        // sessionStorage.setItem('vanList', res.data.data)
    }

    return (
        <div>
            <HomeNavbar></HomeNavbar>
            {
                Array.isArray(vanList)? <CustomGrid  xsmall_cols={1} small_cols={2} medium_cols={3} comps={
                    vanList.map((obj, idx) => (
                        <CustomCard img={`data:image/webp;base64,${obj.image}`} title={
                            <div style={{textAlign: 'center'}}>
                                {obj.name}
                            </div>
                        } content={
                            <div style={{textAlign: 'center'}}>
                                <p>{`${obj.make} ${obj.brand}`}</p>
                                <h5>
                                    {
                                        obj.badges && Array.isArray(obj.badges)? obj.badges.map((value, index) => (
                                            <Badge bg="secondary" className="mx-1">{value}</Badge>
                                        )) : <div>Loading...</div>
                                    }
                                </h5>
                            </div>
                        } buttons={
                            <div style={{width: '100%', textAlign: 'center'}}>
                                <Button style={{marginRight: '8px', width: '45%', backgroundColor: '#1567a5'}} variant="primary" className="mt-3" onClick={ () => { navigate(`/vehicles/details?id=${obj._id}`); } }>See more details</Button>
                                <Button style={{width: '45%'}} variant="success" className="mt-3" onClick={ () => { navigate(`/contact`); } }>Rent now</Button>
                            </div>
                        }></CustomCard>
                    ))
                }></CustomGrid> : <div>Loading...</div>
            }
        </div>
    )
}

export default VehiclesListPage;