import axios from 'axios';


export const CustomPOST = async (apiroute, data, token='') => {
    return await axios.post(`${process.env.REACT_APP_BACKEND_URL}${apiroute}`, data, {headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    }})
}

export const CustomGET = async (apiroute, token='') => {
    const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}${apiroute}`, {headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    }})
    return res
}

export const CustomPUT = async (apiroute, data, token='') => {
    const res = await axios.put(`${process.env.REACT_APP_BACKEND_URL}${apiroute}`, data, {headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    }})
    return res
}

export const CustomDELETE = async (apiroute, token='') => {
    const res = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}${apiroute}`, {headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    }})
    return res
}
