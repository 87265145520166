import React, { Fragment } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Outlet } from 'react-router-dom';
import VehicleDetailsPage from './Pages/VehicleDetails';
import VehiclesListPage from './Pages/VehiclesList';
import WeAreWorking from './Pages/WeAreWorking';
import LandingPage from './Pages/LandingPage';
import ContactPage from './Pages/ContactPage';


function App() {
  return (
    <Router>
      <Fragment>
        <Routes>
          <Route exact path="" element={<LandingPage />} />
          <Route exact path="/vehicles" element={<VehiclesListPage />} />
          <Route path="/vehicles/details" element={<VehicleDetailsPage />} />
          <Route exact path="/contact" element={<ContactPage />} />
          <Route path="*" element={<WeAreWorking />} />
        </Routes>
      </Fragment>
    </Router>
  );
}

export default App;
