import { React, useState, useEffect} from "react";
import '../App.css';
import HomeNavbar from "../Components/CustomNavbar";


const WeAreWorking = () => {
    return (
        <div>
            <HomeNavbar></HomeNavbar>

            <div style={{height: '100%', textAlign: 'center', alignContent: 'center', alignItems: 'center', justifyContent:'center', justifyItems: 'center'}}>
                <img style={{width: '80%'}} src="/assets/weareworking.webp"></img>
            </div>
        </div>
    )
}

export default WeAreWorking;