import { React, useState, useEffect} from "react";
import '../App.css';
import HomeNavbar from "../Components/CustomNavbar";
import CustomGrid from "../Components/CustomGrid";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";


const LandingPage = () => {
    const navigate = useNavigate()

    return (
        <div>
            <HomeNavbar></HomeNavbar>
            <div style={{height: '80vh', textAlign: 'center', justifyContent: 'center', alignItems: 'center', alignContent: 'center'}}>
                <CustomGrid xsmall_cols={1} small_cols={1} medium_cols={2} comps={[
                    <div style={{height: '80vh', textAlign: 'center', height: '100%', alignItems: 'center', alignContent: 'center'}}>
                        <h1>Rent. Drive. Go.</h1>
                        <h5 className="mb-4">LVSprinters – Your Key to Hassle-Free Van Rentals.</h5>
                        <Button style={{backgroundColor: '#1567a5'}} type="primary" onClick={ () => { navigate('/vehicles') } }>Discover Our Fleet of Vans</Button>
                    </div>,
                    <div style={{height: '80vh', textAlign: 'center', height: '100%', alignItems: 'center', alignContent: 'center'}}>
                        <img width={'100%'} src="/assets/van-landing.webp"></img>
                    </div>
                ]}></CustomGrid>
            </div>
        </div>
    )
}

export default LandingPage;