import { React, useState, useEffect} from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import {Navbar, Container, Offcanvas, Nav, NavDropdown, Form, Button, Card} from 'react-bootstrap';


const CustomCard = ({img, title, content, buttons}) => {
    return (
        <Card className="m-3">
            <Card.Img variant="top" src={img} style={{aspectRatio: '4/3', objectFit: 'cover'}} />
            <Card.Body>
                <Card.Title>
                    {
                        title
                    }
                </Card.Title>
                <Card.Text>
                    {
                        content
                    }
                    {
                        buttons
                    }
                </Card.Text>
            </Card.Body>
        </Card>
    )

}

export default CustomCard